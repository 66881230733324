<template lang="pug">
.vc-campaign-share-ship-state-button
  template(v-if="state === 'registered'")
    k-tag(type="is-warning")
      span {{ aasmStateLocaleText('promoter/campaign_share_ship', 'state_for_kol', 'registered') }}

  template(v-if="state === 'invited'")
    k-tag(type="is-warning")
      span {{ aasmStateLocaleText('promoter/campaign_share_ship', 'state_for_kol', 'invited') }}

  template(v-if="state === 'joined'")
    k-tag(type="ci-blue")
      span {{ aasmStateLocaleText('promoter/campaign_share_ship', 'state_for_kol', 'joined') }}
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore, useModal, useInstance } from 'skid-composables'
import KTag from '../common/k-tag.vue'

export default defineComponent({
  components: {
    KTag
  },

  props: {
    promoterCampaignShareShip: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const vueInstance = useInstance()

    const state = computed(() => {
      return props.promoterCampaignShareShip.state
    })

    return {
      state
    }
  }

  // computed: {
  //   promoterShare() {
  //     return this.$store.getters['promoterShares/find'](
  //       this.promoterCampaignShareShip.share_id
  //     )
  //   },

  //   promoter() {
  //     return this.$store.getters['promoters/find'](
  //       this.promoterShare.promoter_id
  //     )
  //   },

  //   promoterCampaign() {
  //     return this.$store.getters['promoterCampaigns/find'](
  //       this.promoterCampaignShareShip.campaign_id
  //     )
  //   }
  // },

  // // created() {},
  // // mounted() {},
  // methods: {
  //   joinConfirm() {
  //     new ActionConfirmService({
  //       title: `確定讓${this.promoter.name}加入${this.promoterCampaign.name}`,
  //       text: this.messageLocaleText(
  //         'confirmations.this_action_can_not_retrieve'
  //       ),
  //       confirmButtonText: '確認加入'
  //     }).confirm(this.joinHandler)
  //   },

  //   async joinHandler() {
  //     await this.$store.dispatch(
  //       'promoterCampaignShareShips/acceptRegistration',
  //       {
  //         share_id: this.promoterShare.id,
  //         campaign_id: this.promoterCampaign.id
  //       }
  //     )

  //     this.$store.dispatch('addFlashMessage', [
  //       'success',
  //       `${this.promoter.name}已成功加入${this.promoterCampaign.name}`
  //     ])
  //   }
  // }
})
</script>
