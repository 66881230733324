<template lang="pug">
.k-form-card
  k-loading(
    :active.sync="loading"
    :is-full-page="true"
  )
  .header(v-if="title || $slots.header")
    span.title {{ title }}
    slot(name="header")
  .content
    .body
      slot(name="body")
    .action(v-if="slots.action")
      slot(name="action")
</template>

<script>
import { defineComponent, useSlots } from 'vue'
import KLoading from '../common/k-loading.vue'

export default defineComponent({
  components: {
    KLoading
  },

  props: {
    title: {
      type: String,
      requird: false
    },

    loading: {
      type: Boolean,
      required: false
    }
  },

  setup(props, { emit }) {
    const slots = useSlots()

    return {
      slots
    }
  }
})
</script>
