<template lang="pug">
.vc-products-show-container-basic-info-slideshow
  .slideshow-wrapper
    swiper(
      :options="swiperOption"
      ref="swiperTop"
    )
      swiper-slide(
        v-for="(image, index) in images"
        :key="image.id"
      )
        template(v-if="index === 0")
          img.image(:src="image.image.url")
        template(v-else)
          img.image.swiper-lazy(:data-src="image.image.url")
          .swiper-lazy-preloader
      .swiper-pagination(slot="pagination")
  .thumbs-wrapper
    swiper.gallery-thumbs(
      :options="swiperOptionThumbs"
      ref="swiperThumbs"
    )
      swiper-slide(
        v-for="image in images"
        :key="image.id"
      )
        img.image(:src="image.image.thumb.url")
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    swiper,
    swiperSlide
  },
  // mixins: [],
  props: {
    images: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      swiperOption: {
        grabCursor: true,
        preloadImages: false,
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },

      swiperOptionThumbs: {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      }
    }
  },
  // computed: {},
  // created() {},
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper

      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },

  methods: {
    slideToImage(imageId) {
      const index = this.images.findIndex((image) => image.id === imageId)

      this.$refs.swiperTop.swiper.slideTo(index)
    }
  }
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/color'

.vc-products-show-container-basic-info-slideshow
  @extend %is-slider
  +utils.from(size.$ipad)
    width: 100%
  > .slideshow-wrapper
    padding-bottom: 100%
    > .swiper-container
      border-radius: size.$radius-normal

  .gallery-thumbs
    $size: 70px
    margin-top: 1rem
    height: $size
    display: none
    +utils.from(size.$iphone12-pro-max)
      display: block
    .swiper-slide
      width: $size
      height: $size
      opacity: 0.4
      cursor: pointer
      > .image
        border-radius: size.$radius-normal
    .swiper-slide-active
      opacity: 1

  .swiper-pagination-bullet
    background-color: color.$light
  .swiper-pagination-bullet-active
    background-color: color.$ci
</style>
