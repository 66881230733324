<template lang="pug">
.vc-kol-center-campaign-card-action-button(v-if="hasButton")
  template(v-if="promoterShare.isDataLoaded()")
    template(
      v-if="promoterCampaignShareShip.state === 'waited' && promoterCampaign.isOnRegistration()"
    )
      registration-full-view(
        v-if="promoterCampaign.isRegistrationFull()"
        :promoter-campaign="promoterCampaign"
      )

      register-button(
        v-else
        :promoter-share="promoterShare"
        :promoter-campaign="promoterCampaign"
        :is-show-register="isShowRegister"
      )

    join-button(
      v-else-if="promoterCampaignShareShip.state === 'invited'"
      :promoter-share="promoterShare"
      :promoter-campaign="promoterCampaign"
      :joined-handler="campaignJoinedHandler"
    )

    registered-button(
      v-else-if="promoterCampaignShareShip.state === 'registered'"
      :promoter-share="promoterShare"
      :promoter-campaign="promoterCampaign"
    )

    joined-button(
      v-else-if="showJoinedButton && promoterCampaignShareShip.state === 'joined'"
      :promoter-share="promoterShare"
      :promoter-campaign="promoterCampaign"
    )

    rejected-button(
      v-else-if="promoterCampaignShareShip.state === 'rejected'"
      :promoter-share="promoterShare"
      :promoter-campaign="promoterCampaign"
    )
  template(v-else-if="promoterCampaign.isOnRegistration()")
    registration-full-view(
      v-if="promoterCampaign.isRegistrationFull()"
      :promoter-campaign="promoterCampaign"
    )
    guest-register-button(
      v-else
      :promoter-campaign="promoterCampaign"
    )
</template>

<script setup>
import { computed, defineComponent, onMounted } from 'vue'
import KButton from '@sharedComponents/common/k-button.vue'
import { useStore, useInstance, useI18n } from 'skid-composables'
// import useToast from '../../../../shared/composables/use_toast'
import PromoterCampaignShareShip from '../../../../shared/resource_models/promoter_campaign_share_ship'
import RegisterButton from './campaign_action_button/register-button.vue'
import JoinButton from './campaign_action_button/join-button.vue'
import JoinedButton from './campaign_action_button/joined-button.vue'
import RejectedButton from './campaign_action_button/rejected-button.vue'
import RegisteredButton from './campaign_action_button/registered-button.vue'
import GuestRegisterButton from './campaign_action_button/guest-register-button.vue'
import RegistrationFullView from './campaign_action_button/registraion-full-view.vue'

const props = defineProps({
  promoterShare: { type: Object },
  promoterCampaign: { type: Object, required: true },
  showJoinedButton: {
    type: Boolean,
    required: false,
    default() {
      return false
    }
  },

  isShowRegister: { type: Boolean }
})

const emit = defineEmits(['campaign-joined'])
const store = useStore()
const vueInstance = useInstance()

const isLoading = computed(() => {
  return store.getters['promoterCampaignShareShips/isLoading']
})

const promoterCampaignShareShip = computed(() => {
  let campaignShareShip = store.getters['promoterCampaignShareShips/all'].find(
    (shareShip) => {
      return (
        shareShip.campaign_id === parseInt(props.promoterCampaign.id) &&
        shareShip.share_id === parseInt(props.promoterShare.id)
      )
    }
  )

  if (!campaignShareShip)
    campaignShareShip = new PromoterCampaignShareShip({
      campaign_id: props.promoterCampaign.id,
      state:
        props.promoterCampaign.category === 'official' ? 'joined' : 'waited'
    })

  return campaignShareShip
})

const campaignJoinedHandler = () => {
  emit('campaign-joined')
}

onMounted(() => {})

const hasButton = computed(() => {
  if (props.promoterShare.isDataLoaded()) {
    if (
      promoterCampaignShareShip.value.state === 'waited' &&
      props.promoterCampaign.isOnRegistration()
    )
      return true
    if (promoterCampaignShareShip.value.state === 'invited') return true
    if (promoterCampaignShareShip.value.state === 'registered') return true
    if (promoterCampaignShareShip.value.state === 'rejected') return true

    if (
      props.showJoinedButton &&
      promoterCampaignShareShip.value.state === 'joined'
    )
      return true
  } else if (props.promoterCampaign.isOnRegistration()) {
    return true
  }
})
</script>
