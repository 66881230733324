export const FETCH_LINE_BOT_ACCOUNTS_SUCCESS = 'FETCH_LINE_BOT_ACCOUNTS_SUCCESS'
export const GET_RELATED_LINE_BOT_ACCOUNTS_SUCCESS =
  'GET_RELATED_LINE_BOT_ACCOUNTS_SUCCESS'
export const GET_LINE_BOT_ACCOUNT_SUCCESS = 'GET_LINE_BOT_ACCOUNT_SUCCESS'
export const ADD_LINE_BOT_ACCOUNT_SUCCESS = 'ADD_LINE_BOT_ACCOUNT_SUCCESS'
export const UPDATE_LINE_BOT_ACCOUNT_SUCCESS = 'UPDATE_LINE_BOT_ACCOUNT_SUCCESS'
export const DELETE_LINE_BOT_ACCOUNT_SUCCESS = 'DELETE_LINE_BOT_ACCOUNT_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
