<template lang="pug">
.vc-kolcenter-home-line-bot-account-form
  k-form-card(title="加入官方@LINE Kstar")
    template(#body)
      p 已收到您報名檔期的通知，為確保流程通順，KOLNET將在活動期間，透過官方LINE布達相關資訊給您✨請務必加入，後續將由專人安排樣品配送、提供您專屬賣場連結、協助您熟悉後台功能。
      .qr-code-wrapper
        .qr-code
    template(#action)
      .button-wrapper
        k-button(
          type="ci-blue"
          :href="lineUrl"
          target="_blank"
        )
          | 加入Line@
</template>

<script setup>
import { ref, reactive, computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import kFormCard from '../../common/k-form-card.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import QrCreator from 'qr-creator'

const props = defineProps({})
const store = useStore()

const data = reactive({ lineBotAccountLinkToken: null })

const getLineBotAccountLinkToken = async () => {
  const response = await store.dispatch(
    'promoterMembers/getLineBotAccountLinkToken'
  )

  data.lineBotAccountLinkToken = response.data.data
}

const lineUrl = computed(() => {
  if (!data.lineBotAccountLinkToken) return null

  return data.lineBotAccountLinkToken.attributes.line_bot_message
})

const renderQrCode = () => {
  QrCreator.render(
    {
      text: lineUrl.value,
      radius: 0,
      background: 'white'
    },
    document.querySelector('.qr-code')
  )
}

onMounted(async () => {
  await getLineBotAccountLinkToken()

  setTimeout(() => renderQrCode(), 1000)
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/size.sass'

.vc-kolcenter-home-line-bot-account-form
  .k-form-card
    +utils.is-flat-card
    padding: size.$gap
    .content
      box-shadow: none

    .button-wrapper
      +utils.is-flex(vc, hc)
      +utils.from(size.$ipad)
        display: none
      .button
        width: 100%
  .qr-code-wrapper
    padding: 1rem 0
    display: none
    +utils.from(size.$ipad)
      +utils.is-flex(vc, hc)
</style>
