<template lang="pug">
.vc-kolcenter-promoter-campaign-campaign-action-button-register-form-successful-view(
  v-if="data.isDataInited"
)
  template(v-if="lineBotAccount.isNewRecord()")
    line-bot-account-form

  template(v-else)
    k-form-container.notification-wrapper(style-type="kolcenter-card")
      template(slot="body")
        .icon-wrapper.has-text-centered.has-text-success.mb-4.mt-3
          .icon
            i.fa.fa-check-circle.fa-3x
        .title.is-size-5 報名成功
        .info.mb-3
          span {{ `您已成功報名${promoterCampaign.name}，後台將進行審核，我們會於檔期開始前通知您審核結果！請留意官方帳號訊息💙` }}

      template(slot="footer")
        k-button(
          type="ci-blue"
          @click="closeModal"
        )
          | 好的，我已了解
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import kFormContainer from '@sharedComponents/common/k-form-container.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import kLoading from '@kolcenterComponents/common/k-loading.vue'
import LineBotAccountForm from '@kolcenterComponents/kolcenter_home/common/line-bot-account-form.vue'

import { useStore, useInstance } from 'skid-composables'

const props = defineProps({
  promoterShare: { type: Object, required: true },
  promoterCampaign: { type: Object, required: true }
})

const emit = defineEmits(['close'])

const data = reactive({
  isDataInited: false
})
const store = useStore()

// const data = reactive({})

// const lineUrl = computed(() => 'https://page.line.me/kolnet_kstar')

const errors = computed(() => form.model.errors)

const promoterMember = computed(() => {
  return props.promoterShare.getPromoterMember(store)
})

const getLineBotAccount = async () => {
  await store.dispatch('promoterMembers/getLineBotAccount')
}

const lineBotAccount = computed(() => {
  return store.getters['lineBotAccounts/findBy']({
    memberable_type: 'PromoterMember',
    memberable_id: parseInt(promoterMember.value.id)
  })
})

const closeModal = () => {
  emit('close')
}

onMounted(async () => {
  await getLineBotAccount()

  data.isDataInited = true
})
</script>
