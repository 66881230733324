<template lang="pug">
b-dropdown.k-dropdown(
  :class="containerClass"
  :value="value"
  :placeholder="placeholder"
  :size="size"
  :expanded="expanded"
  :disabled="disabled"
  @input="inputHandler"
)
  template(#trigger="{ active }")
    b-button
      b-icon.selected(
        v-if="selectedItem && selectedItem.icon"
        :pack="selectedItem.iconPack"
        :icon="selectedItem.icon"
      )
      span {{ selectedItem ? selectedItem.label : placeholder }}
      b-icon.trigger(
        pack="fa"
        :icon="active ? 'chevron-up' : 'chevron-down'"
      )
  b-dropdown-item(
    v-for="(item, index) in items"
    :key="index"
    :value="item.value"
  )
    .dropdown-content-wrapper
      .icon-left(v-if="item.icon")
        b-icon(
          :pack="item.iconPack"
          :icon="item.icon"
        )
      .value
        span {{ item.label }}
</template>

<script setup>
import { computed } from 'vue'

const STYLE_TYPE_MAP = {
  kolcenter: 'kolcenter-type'
}

const props = defineProps({
  value: { type: [String, Number] },
  items: { type: Array },
  size: { type: String },
  placeholder: { type: String, default: () => '請選擇...' },
  expanded: { type: Boolean },
  disabled: { type: Boolean },

  styleType: { type: String }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const selectedItem = computed(() => {
  return props.items.find((item) => {
    return item.value === props.value
  })
})

const containerClass = computed(() => {
  return [STYLE_TYPE_MAP[props.styleType]].filter(Boolean).join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/size'

.k-dropdown
  &.kolcenter-type
    width: 100%
    height: 44px
    button
      width: 100%
      height: 100%
      border: 1px solid color.$white-lilac
      border-radius: size.$radius-normal
      text-align: center
      font-size: font.$normal
      font-weight: font.$semibold
      color: color.$ci
      position: relative
      .icon.selected
        position: absolute
        border-color: color.$ci!important
        left: 1.125rem
      .icon.trigger
        position: absolute
        border-color: color.$ci!important
        right: 1.125rem
    .dropdown-menu
      text-align: center
      .dropdown-content
        border-radius: size.$gap
      .dropdown-content .dropdown-item
        color: color.$ci
        font-weight: font.$semibold
        &.is-active
          background: color.$ci
          color: color.$white
      .dropdown-content .dropdown-item .dropdown-content-wrapper
        display: grid
        grid-template-columns: auto 1fr auto
        .value
          grid-column: 2
</style>
