<template lang="pug">
.k-previous-page-link
  a(
    :href="href"
    @click="clickHandler"
  )
    .icon
      i.fa.fa-chevron-left
    slot
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  href: { type: String }
})

const emit = defineEmits(['click'])

const clickHandler = () => {
  emit('click')
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/size'

.k-previous-page-link
  font-size: font.$size-5
  font-weight: font.$semibold
  a
    color: color.$black
  +utils.from(size.$ipad)
    a
      color: color.$black
      text-shadow: none
</style>
