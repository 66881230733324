import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'line_bot_accounts',
  attributes: [
    'id',
    'scope',
    'status',
    'display_name',
    'picture_url',
    'status_message',
    'memberable_type',
    'memberable_id',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

export default class LineBotAccount extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  unbind() {
    return axios.post(`${this.apiBasePath()}/${this.id}/unbind`)
  }
}
