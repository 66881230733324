<template lang="pug">
k-card.vc-shared-promoter-campaign-official-campaign-card(
  style-type="campaign-card-v2"
)
  template(#header-wrapper)
    img.avatar(:src="iconUrl")
    .title {{ brandName }}
    .action
      k-icon(icon="mdi-share")
      span {{ resource.code }}

  template(#image-wrapper)
    k-slide-show(
      style-type="in-card"
      style="--slide-height: 100%"
      :slides="slides"
      :options="{ hasProgressText: true, hasCover: true }"
    )

  template(#info-wrapper)
    .title {{ resource.name }}
    .content
      .period
        overall-time-with-period-range-view(
          :promoter-campaign="resource"
          display-type="oneline"
        )
      .required-number(v-if="resource.limit_shares_count")
        k-icon(icon="mdi-account-multiple")
        span 需求
        span {{ resource.limit_shares_count }}
        span 人
      .share-percentage(v-if="sharePercentage")
        .icon
          i.mdi.mdi-hexagon-slice-4
        span 銷售
        span {{ sharePercentage }}%
        span 分潤

  template(#footer-wrapper)
    k-button(
      type="light"
      :href="showLink"
    ) {{ detailText }}

    campaign-action-button(
      :promoter-share="promoterShare"
      :promoter-campaign="resource"
      :show-joined-button="true"
    )
</template>

<script setup>
import { computed } from 'vue'
import { useStore, useSwiper } from 'skid-composables'
import CampaignActionButton from '@kolcenterComponents/promoter_campaign/campaign-action-button.vue'
import KIcon from '@sharedComponents/common/k-icon.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import kCardContainer from '@sharedComponents/common/k-card-container.vue'
import kCard from '@sharedComponents/common/k-card.vue'
import kSlideShow from '@sharedComponents/common/k-slide-show.vue'
import OverallTimeWithPeriodRangeView from '@sharedComponents/promoter_campaign/common/overall-time-with-period-range-view.vue'
import DefaultKolnetIcon from '@kolcenter/images/default-kolnet.png'
import useCampaignBannerService from '@kolcenter/js/composables/promoter_campaigns/use_campaign_banner_service.js'

const store = useStore()
const props = defineProps({
  promoterShare: { type: Object, required: true },
  resource: { type: Object, required: true }
})

const iconUrl = computed(() => {
  return brand.value.isDataLoaded() ? brand.value.logo.url : DefaultKolnetIcon
})

const { swiperOptions } = useSwiper({
  autoplay: false
})

const brandName = computed(() => {
  return brand.value.name
})

const brand = computed(() => {
  return store.getters['brands/findBy']({
    partner_id: parseInt(props.resource.partner_id)
  })
})

const partner = computed(() => {
  return store.getters['partners/find'](props.resource.partner_id)
})

const showLink = computed(() => {
  return `/kolcenter/official_campaigns/${props.resource.code}`
})

const promoterLevel = computed(() => {
  return store.getters['promoterLevels/find'](props.promoterShare.level_id)
})

const sharePercentage = computed(() => {
  if (!promoterLevel.value) return null

  return Math.min(promoterLevel.value.profit_ratio)
})

const slides = computed(() => {
  const campaignBannerService = useCampaignBannerService(props.resource)

  return campaignBannerService.images.value
})

const detailText = computed(() => {
  switch (props.resource.progress()) {
    case 'not_open':
      return '檔期即將開放報名'
    case 'registration':
      return '暸解更多'
    case 'will_open':
      return '報名已結束，檔期即將開始'
    case 'for_sale':
      return '檔期銷售中'
    default:
      return '暸解更多'
  }
})
</script>
