<template lang="pug">
.vc-kolcenter-campaign-rejected-view
  k-form-card(:title="`報名${promoterCampaign.name}未通過`")
    template(slot="body")
      .section
        div(v-html="PromoterCampaignShareShip.rejectedReason(true)")

    template(slot="action")
      k-button(
        type="ci-blue"
        @click="closeModal"
      )
        | 我已了解
</template>

<script setup>
import { defineComponent, reactive, computed, onMounted } from 'vue'
import KFormCard from '../../common/k-form-card.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import PromoterCampaignShareShip from '@models/promoter_campaign_share_ship.js'
import kLoading from '../../common/k-loading.vue'

import {
  useToast,
  useStore,
  useInstance,
  useModal,
  useI18n
} from 'skid-composables'

const props = defineProps({
  promoterShare: {
    type: Object,
    required: true
  },

  promoterCampaign: {
    type: Object,
    required: true
  }
})

const vueInstance = useInstance()
const store = useStore()

const closeModal = () => {
  vueInstance.$parent.close()
}

onMounted(async () => {})
</script>

<style lang="sass">
@use '../../../../css/utils'
@use '../../../../css/abstracts/size'
@use '../../../../css/abstracts/font'
@use '../../../../css/abstracts/color'

.vc-kolcenter-campaign-rejected-view
  +utils.is-flat-card
  padding: size.$gap

  .remember-sample-address
    display: flex
    align-items: center

  .k-form-card
    .content
      box-shadow: none
</style>
