<template lang="pug">
.vc-kolcenter-product-show-container-basic-info-view
  .product-image-wrapper
    product-slideshow(
      ref="slider"
      :images="images"
    )
    //- product-info-card-mobile(:product="product")

  .product-info-wrapper
    .shopping-options-info
      template(v-if="promoterShare && promoterEventProductShip")
        product-info-card(
          v-if="promoterShare && promoterEventProductShip"
          :product="product"
          :promoter-event-product-ship="promoterEventProductShip"
          :promoter-share="promoterShare"
        )
      template(v-else)
        product-info-card-for-guest(
          :product="product"
          :promoter-event-product-ship="promoterEventProductShip"
        )
    .register-button-wrapper(v-if="promoterCampaign")
      campaign-action-button(
        :promoter-campaign="promoterCampaign"
        :promoter-share="promoterShare"
      )
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'skid-composables'
import ProductSlideshow from './basic_info_view/slideshow.vue'
import ProductInfoCard from './basic_info_view/info-card.vue'
import ProductInfoCardForGuest from './basic_info_view/info-card-for-guest.vue'
import campaignActionButton from '../../promoter_campaign/campaign-action-button.vue'

const props = defineProps({
  product: { type: Object },
  promoterEventProductShip: { type: Object },
  promoterShare: { type: Object }
})

const store = useStore()

const brand = computed(() => {
  return store.getters['brands/find'](props.product.brand_id)
})

const variants = computed(() => {
  return store.getters['productVariants/forProduct'](props.product.id)
})

const images = computed(() => {
  return store.getters['productImages/forProduct'](props.product.id)
})

const supportDocuments = computed(() => {
  return store.getters['productSupportDocuments/all']
})

const promoterCampaign = computed(() => {
  if (
    !props.promoterEventProductShip ||
    props.promoterEventProductShip.isNewRecord()
  )
    return

  return store.getters['promoterCampaigns/find'](
    props.promoterEventProductShip.campaign_id
  )
})
</script>

<style lang="sass">
@use 'sass:math'
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/color'

.vc-kolcenter-product-show-container-basic-info-view
  +utils.from(size.$ipad)
    display: flex
  .product-image-wrapper
    +utils.from(size.$ipad)
      width: 50%
      padding-right: 0
    > .product-info
      margin-top: math.div(size.$gap, 2)

  .product-info-wrapper
    display: flex
    flex-direction: column
    gap: size.$gap
    +utils.from(size.$ipad)
      justify-content: space-between
      padding-left: size.$gap * 2
      width: 50%
    .register-button-wrapper
      +utils.from(size.$ipad)
        padding: 0 1.5rem
      .button
        height: 44px
        width: 100%
</style>
