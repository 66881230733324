<template lang="pug">
//- 一開始使用於 kolcenter 檔期清單
.k-card-container
  template(v-if="data.length > 0")
    .cards-wrapper
      slot(
        v-for="(row, index) in data"
        name="card"
        v-bind="{ row }"
      )
    .pagination-wrapper(v-if="paginated")
      b-pagination(
        :total="total"
        :current="currentPage"
        order="is-centered"
        size="is-small"
        rounded
        :per-page="perPage"
        @change="onPageChange"
      )
  //- template(v-else)
  //-   k-empty
</template>

<script setup>
import { computed } from 'vue'
import DefaultKolnetSmall from '@kolcenter/images/default-kolnet.png'
import KEmpty from '@sharedComponents/common/k-empty.vue'

const props = defineProps({
  data: { type: Array, required: true },
  paginated: { type: Boolean },
  total: { type: Number },
  currentPage: { type: Number },
  perPage: { type: Number }
})

const emit = defineEmits(['page-change'])

const onPageChange = (page) => {
  emit('page-change', page)
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/color'
@use 'bourbon/core/bourbon'

.k-card-container
  --grid-template-columns: repeat(auto-fill, minmax(330px, 1fr))

  .cards-wrapper
    margin-block: 1rem
    @extend %is-cards-wrapper
    grid-template-columns: var(--grid-template-columns)
</style>
