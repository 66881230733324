<template lang="pug">
.vc-product-show-container-basic-info-view-info-card
  .header-bar
    .info-column
      card-title(
        v-if="brand.isDataLoaded()"
        :title="brand.name"
        :avatar-url="brand.logo.thumb.url"
      )

      h1.name {{ product.name }}

      .new-price
        | {{ `自選帶貨價： NTD ${toMoney(masterPromoterEventVariantShip.price).format()}元/個` }}

  .info-detail
    .section.ori-price
      .title
        .icon
          i.fa.fa-dollar
        .name
          | 各通路售價
      .value
        | {{ `NTD ${toMoney(product.consumer_price).format()} /個 (含稅)` }}
    .section.profit-share
      .title
        .icon
          i.fa.fa-money
        .name
          | 開團報酬
      .value.emphasis
        | {{ `${promoterLevel.en_name} ${minProfitRatio}% 分潤` }}
    .section(v-if="promoterEventProductShip.property_reserved_date")
      .title
        .icon
          i.fa.fa-archive
        .name
          | {{ attributeLocaleText('promoter/event_product_ship', 'property_reserved_date') }}
      .value
        | {{ promoterEventProductShip.property_reserved_date }}
    //- .section.shipment
    //-   .title
    //-     .icon
    //-       i.fa.fa-truck
    //-     .name
    //-       | 運費條件
    //-   .value
    //-     | {{ promoterEventProductShip.property_shipping_condition || '無' }}
    //- .section.gift
    //-   .title
    //-     .icon
    //-       i.fa.fa-gift
    //-     .name
    //-       | {{ attributeLocaleText('promoter/event_product_ship', 'property_gift_note') }}
    //-   .value
    //-     | {{ promoterEventProductShip.property_gift_note || '無' }}
    //- .section.promotion
    //-   .title
    //-     .icon
    //-       i.fa.fa-truck
    //-     .name
    //-       | {{ attributeLocaleText('promoter/event_product_ship', 'property_free_shipment_condition') }}
    //-   .value
    //-     | {{ promoterEventProductShip.property_free_shipment_condition || '無' }}
</template>

<script>
import CardTitle from '../../../common/card-title.vue'

export default {
  components: { CardTitle },

  mixins: [],

  props: {
    product: { type: Object, required: true },
    promoterEventProductShip: { type: Object, required: true },
    promoterShare: { type: Object, required: true }
  },

  // data() {
  //   return {}
  // },

  computed: {
    masterPromoterEventVariantShip() {
      return this.$store.getters['promoterEventVariantShips/all'].find(
        (ship) => {
          return ship.variant_id === parseInt(this.product.master.id)
        }
      )
    },

    brand() {
      return this.$store.getters['brands/find'](this.product.brand_id)
    },

    promoterShareLevel() {
      return this.$store.getters['promoterShareLevels/findCurrentByShareId'](
        this.promoterShare.id
      )
    },

    promoterLevel() {
      return this.$store.getters['promoterLevels/find'](
        this.promoterShareLevel.level_id
      )
    },

    promoterCampaign() {
      return this.$store.getters['promoterCampaigns/find'](
        this.promoterEventProductShip.campaign_id
      )
    },

    minProfitRatio() {
      switch (this.promoterCampaign.profit_calculate_method) {
        case 'by_promoter_level':
          return Math.min(
            parseFloat(this.promoterLevel.profit_ratio),
            parseFloat(this.promoterEventProductShip.max_profit_ratio)
          )
        case 'by_campaign_level':
          const campaignLevel =
            this.promoterCampaign.profit_calculate_config.campaign_levels.find(
              (c) => {
                return (
                  parseInt(c.level_id) ===
                  parseInt(this.promoterShareLevel.level_id)
                )
              }
            )

          return Math.min(
            parseFloat(campaignLevel.profit_ratio),
            parseFloat(this.promoterEventProductShip.max_profit_ratio)
          )
      }
    }
  },

  watch: {
    // masterVariant(newValue) {
    //   this.selectedVariantId = newValue.id
    // }
  },

  // created() {},
  mounted() {
    // this._setPaddingBottom()
  },

  methods: {
    _setPaddingBottom() {
      const actionBarHeight = this.$el.querySelector('.action-bar').offsetHeight

      this.$el.style.paddingBottom = `${actionBarHeight}px`
    }
  }
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'

.vc-product-show-container-basic-info-view-info-card
  position: relative
  padding: 0
  +utils.from(size.$ipad)
    padding: 0 1.5 * size.$gap
  height: 100%
  border-radius: 5px

  .vc-product-quantity-picker > .picker-unit,
  .header-bar > .info-column > .spec-description
    border-radius: size.$radius-normal

  .button
    border-radius: size.$radius-medium

  // header bar
  .header-bar
    padding-bottom: size.$gap

  .info-column
    display: flex
    flex-direction: column
    > h1.name
      font-weight: font.$bold
      font-size: font.$size-6
      padding-bottom: size.$gap * 0.75
      +utils.from(size.$ipad)
        font-size: font.$size-5
    > .name
      word-break: break-all
      max-height: 3em
      overflow: hidden
    > .new-price
      font-size: font.$size-6
      padding-bottom: size.$gap * 0.75

  .info-detail
    display: grid
    grid-template-columns: repeat(2,1fr)
    border-radius: size.$radius-normal
    box-shadow: size.$shadow-tiny
    > .section
      padding: size.$gap
      padding-top: size.$gap * 0.75
      border-bottom: 1px solid color.$concrete
      &:nth-child(odd)
        border-right: 1px solid color.$concrete
    > .section > .title
      font-size: font.$size-7
      line-height: 2
    > .section > .title > .icon
      display: inline-block
    > .section > .title > .name
      display: inline-block
    > .section > .value
      font-size: font.$size-7
      line-height: 2
      &.emphasis
        color: color.$ci

  // offers info
  //.offers-info

  .info-unit
    +utils.is-flex
    flex-direction: row
    justify-content: space-between
    padding: size.$gap 0
    font-size: font.$small
    font-weight: font.$bold
    border-bottom: 1px solid color.$light
    > .label
      margin: 0
    > .price > .type
      margin-right: .5em
      &::before
        +utils.has-currency-before
      &:not(:last-child)
        color: lighten(color.$white-smoke, 15)
        text-decoration: line-through
      &:last-child
        color: color.$ci
        font-size: font.$normal

  .label
    font-size: font.$small
</style>
