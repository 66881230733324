<template lang="pug">
.vc-kolcenter-campaign-register-form
  template(v-if="data.show === 'ig-form'")
    social-media-form(
      :promoter-share="promoterShare"
      @cancel="cancelHandler"
      @data-added="socialMediaDataAdded"
    )
  template(v-if="data.show === 'main-form'")
    main-form(
      :promoter-share="promoterShare"
      :promoter-campaign="promoterCampaign"
      @cancel="cancelHandler"
      @data-saved="dataSavedHandler"
    )
  template(v-if="data.show === 'successful-form'")
    successful-view(
      :promoter-share="promoterShare"
      :promoter-campaign="promoterCampaign"
      @close="closeHandler"
    )
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import { useInstance, useStore } from 'skid-composables'
import socialMediaForm from './register_form/social-media-form.vue'
import mainForm from './register_form/main-form.vue'
import successfulView from './register_form/successful-view.vue'

const props = defineProps({
  promoterShare: { type: Object, required: true },
  promoterCampaign: { type: Object, required: true }
})

const store = useStore()

const emit = defineEmits(['click-cancel', 'click-close'])
const vueInstance = useInstance()

const data = reactive({
  show: 'main-form'
})

const promoterMember = computed(() => {
  return props.promoterShare.getPromoterMember(store)
})

function cancelHandler() {
  vueInstance.$parent.close()
}

function dataSavedHandler() {
  data.show = 'successful-form'
}

function closeHandler() {
  vueInstance.$parent.close()
}

async function fetchMediaAccounts() {
  return store.dispatch('promoterMembers/fetchMediaAccounts', {
    model: promoterMember.value
  })
}

async function fetchMediaSevices() {
  return store.dispatch('promoterMediaServices/all')
}

function socialMediaDataAdded() {
  data.show = 'main-form'
}

const igMediaAccount = computed(() => {
  const igService = store.getters['promoterMediaServices/findBy']({
    name: 'Instagram'
  })

  return store.getters['promoterMediaAccounts/findBy']({
    promoter_member_id: parseInt(promoterMember.value.id),
    media_service_id: parseInt(igService.id)
  })
})

onMounted(async () => {
  if (props.promoterCampaign.match_rule === 'followers_based') {
    await fetchMediaSevices()

    await fetchMediaAccounts()

    data.show = igMediaAccount.value.isNewRecord() ? 'ig-form' : 'main-form'
  } else {
    data.show = 'main-form'
  }
})
</script>
