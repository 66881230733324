<template lang="pug">
.vc-k-kolcenter-product-show-container-header
  k-previous-page-link(@click="clickLastPageHandler")
    span {{ lastPageTitle }}
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import kPreviousPageLink from '@kolcenterComponents/common/k-previous-page-link.vue'

const props = defineProps({
  lastPageTitle: {
    type: String,
    default() {
      return '上一頁'
    }
  }
})

const emit = defineEmits(['click-last-page'])

const clickLastPageHandler = () => {
  emit('click-last-page')
}
</script>
