<template lang="pug">
k-form-container.vc-kolcenter-promoter-campaign-campaign-action-button-register-form-social-media-form(
  style-type="kolcenter-modal-box"
  :loading="!data.isDataInited"
)
  template(#header)
    .title 輸入IG資料
    .subtitle 此檔期報名需要有 IG 帳號以做驗證，您尚未輸入您的 IG 帳號，請依照下方表單輸入
  template(#body)
    k-field(
      style-type="kolcenter"
      :type="errors.errorClassAt('media_service_id')"
      :message="errors.get('media_service_id')"
    )
      k-dropdown(
        :value="form.media_service_id"
        placeholder="選擇媒體平台"
        expanded
        style-type="kolcenter"
        :items="items"
        readonly
        disabled
      )

    k-field(
      style-type="kolcenter"
      label="帳號"
      required
      :type="errors.errorClassAt('username')"
      :message="errors.get('username')"
      horizontal
      :options="{ bordered: true }"
    )
      k-input(
        style-type="kolcenter"
        type="text"
        v-model="form.username"
        placeholder="請輸入您的帳號"
        @input="(data.isEdited = true), errors.clear('username')"
      )

    k-field(
      style-type="kolcenter"
      label="粉絲數"
      required
      :type="errors.errorClassAt('fans_count')"
      :message="errors.get('fans_count')"
      horizontal
      :options="{ bordered: true }"
    )
      k-input(
        style-type="kolcenter"
        v-model="form.fans_count"
        placeholder="請輸入帳號粉絲數"
        @input="(data.isEdited = true), errors.clear('fans_count')"
      )

  template(slot="footer")
    k-button(
      type="shadow"
      :loading="data.isLoading"
      @click="cancelHandler"
    )
      | 稍後再確認
    k-button(
      type="ci-blue"
      :loading="data.isLoading"
      :disabled="data.isEdited === false"
      @click="submitForm"
    )
      | 儲存
</template>

<script setup>
import { computed, onMounted, ref, reactive, watch } from 'vue'
import { useStore, useToast, useInstance } from 'skid-composables'

import Form from 'odd-form_object'
import kFormContainer from '@sharedComponents/common/k-form-container.vue'
import KDropdown from '@sharedComponents/common/k-dropdown.vue'
import KIcon from '@sharedComponents/common/k-icon.vue'
import kField from '@sharedComponents/common/k-field.vue'
import kInput from '@sharedComponents/common/k-input.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import PromoterMediaAccount from '@models/promoter_media_account.js'

const props = defineProps({
  promoterShare: { type: Object, required: true }
})
const emit = defineEmits(['cancel', 'data-added'])

const form = reactive(new Form(new PromoterMediaAccount()))

const store = useStore()
const data = reactive({
  isDataInited: false,
  isLoading: false,
  isEdited: false
})

const errors = computed(() => {
  return form.model.errors
})

const promoterMember = computed(() => {
  return props.promoterShare.getPromoterMember(store)
})

const promoterMediaServices = computed(() => {
  return store.getters['promoterMediaServices/all']
})

const items = computed(() => {
  return promoterMediaServices.value.map((service) => {
    return {
      label: service.name,
      value: parseInt(service.id)
    }
  })
})

function cancelHandler() {
  emit('cancel')
}

async function submitForm() {
  data.isLoading = true

  await store.dispatch('promoterMembers/saveMediaAccount', {
    model: promoterMember.value,
    mediaAccount: form.sync()
  })

  data.isLoading = false

  emit('data-added')
}

const igMediaService = computed(() => {
  return store.getters['promoterMediaServices/findBy']({
    name: 'Instagram'
  })
})

onMounted(async () => {
  form.media_service_id = parseInt(igMediaService.value.id)

  data.isDataInited = true
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
</style>
