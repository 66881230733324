<template lang="pug">
.vc-kolcenter-match-index-container(v-if="isLoaded")
  cards(
    v-if="unanswerMatchProductIds.length > 0"
    :promoter-match-products="promoterMatchProducts"
    @answer="promoterMatchService.answer"
  )

  finished-card(
    v-if="unanswerMatchProductIds.length === 0"
    @click-try-again="tryAgainHandler"
    @click-home="clickHomeHandler"
  )
</template>

<script setup>
import { computed, onMounted } from 'vue'
import Cards from './index_container/cards.vue'
import FinishedCard from './index_container/finished-card.vue'
import usePromoterMatchService from '@kolcenter/js/composables/use_promoter_match_service'

const props = defineProps({
  promoterShareId: { type: [String, Number] }
})

const promoterMatchService = usePromoterMatchService({
  promoterShareId: props.promoterShareId
})

const promoterMatchProducts = computed(
  () => promoterMatchService.promoterMatchProducts.value
)

const unanswerMatchProductIds = computed(
  () => promoterMatchService.unanswerMatchProductIds.value
)

const isLoaded = computed(() => {
  return promoterMatchService.isLoaded.value
})

const tryAgainHandler = promoterMatchService.tryAgainHandler

const clickHomeHandler = () => {
  window.location.href = '/'
}

onMounted(() => {
  promoterMatchService.fetchInitData({
    promoterShareId: props.promoterShareId
  })
})
</script>
