<template lang="pug">
.k-editor-view(:is="component")
  slot
</template>

<script setup>
import { ref, computed } from 'vue'
import Agreement from './k_editor_view/agreement.vue'

const STYLE_TYPE_MAP = {
  agreement: Agreement
}

const props = defineProps({
  styleType: { type: String }
})

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})
</script>
