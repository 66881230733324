<template lang="pug">
k-list-item(:image-url="productVariant.cover.thumb.url")
  template(#info)
    .sku {{ productVariant.sku }}
    .product-name {{ product.name }}
    .variant-name {{ productVariant.name }}
    .stock
      | 樣品庫存:
      | {{ promoterEventVariantShip.sample_stock }}
  template(#action)
    slot(
      name="action"
      :productVariant="productVariant"
    )
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'skid-composables'
import KListItem from '../common/card/k-list-item.vue'

export default defineComponent({
  components: {
    KListItem
  },

  props: {
    promoterEventVariantShip: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore()

    const productVariant = computed(() => {
      return store.getters['productVariants/find'](
        props.promoterEventVariantShip.variant_id
      )
    })

    const product = computed(() => {
      return store.getters['products/find'](productVariant.value.product_id)
    })

    return {
      productVariant,
      product
    }
  }
})
</script>
