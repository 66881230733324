<template lang="pug">
.vc-kolcenter-product-show-container-detail-info-view
  .description-and-comments-wrapper
    b-tabs(ref="tabs")
      b-tab-item(:label="attributeLocaleText('product', 'description')")
        template(v-if="product.description")
          .editor-content
            div(v-html="simpleFormat(product.description)")
        template(v-else)
          .empty-state
            .icon
              i.fa.fa-smile-o
            span
              | {{ messageLocaleText('there_is_no_data_for_now') }}

      b-tab-item(
        :label="attributeLocaleText('product', 'preserve_detail')"
        v-if="product.preserve_detail"
      )
        template(v-if="product.preserve_detail")
          .editor-content
            div(v-html="simpleFormat(product.preserve_detail)")
        template(v-else)
          .empty-state
            .icon
              i.fa.fa-smile-o
            span
              | {{ messageLocaleText('there_is_no_data_for_now') }}

      b-tab-item(
        :label="attributeLocaleText('product', 'promotion_detail')"
        v-if="product.promotion_detail"
      )
        template(v-if="product.promotion_detail")
          .editor-content
            div(v-html="simpleFormat(product.promotion_detail)")
        template(v-else)
          .empty-state
            .icon
              i.fa.fa-smile-o
            span
              | {{ messageLocaleText('there_is_no_data_for_now') }}

      b-tab-item(
        :label="attributeLocaleText('product', 'shipping_detail')"
        v-if="product.shipping_detail"
      )
        template(v-if="product.shipping_detail")
          .editor-content
            div(v-html="simpleFormat(product.shipping_detail)")
        template(v-else)
          .empty-state
            .icon
              i.fa.fa-smile-o
            span
              | {{ messageLocaleText('there_is_no_data_for_now') }}

      b-tab-item(
        :label="attributeLocaleText('product', 'support_documents')"
        v-if="hasSupportDocuments"
      )
        .editor-content
          div(v-html="simpleFormat(product.support_document_detail)")
        product-document-list(:support-documents="supportDocuments")
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'skid-composables'
import ProductDocumentList from '@applicationComponents/product/document-list.vue'

const props = defineProps({
  product: { type: Object }
})

const store = useStore()

const supportDocuments = computed(() => {
  return store.getters['productSupportDocuments/all']
})

const hasSupportDocuments = computed(() => {
  return (
    !!props.product.support_document_detail || supportDocuments.value.length > 0
  )
})
</script>

<style lang="sass">
@use 'sass:math'
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/color'

.vc-kolcenter-product-show-container-detail-info-view
  margin: size.$gap*2 -0.2rem 0

  .b-tabs
    > .tab-content
      padding: size.$gap 0
    > .tabs > ul
      border-bottom: none
      margin-bottom: 3px
    > .tabs > ul > li
      flex: 1
      > a
        padding: 1em
        font-size: font.$small
        font-weight: font.$bold
        border-bottom: none
      &.is-active > a
        color: color.$ci
        > span
          position: relative
          &:after
            content: ''
            display: block
            position: absolute
            bottom: -1em
            width: calc(100% + 1.5rem)
            left: -0.75rem
            height: 2px
            border-radius: 8px
            background-color: color.$ci

  .editor-content
    $editor-image-margin: 0.5rem - 0.2rem

    img
      margin: 0 #{-1 * $editor-image-margin}
      max-width: none
      width: calc(100% + #{2 * $editor-image-margin})
      +utils.from(size.$ipad)
        margin: 0
        max-width: 100%

  .empty-state
    padding: 50px 0
    color: color.$white-smoke
    font-size: font.$size-6
    text-align: center
</style>
