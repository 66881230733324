import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_preferred_category_ships',
  attributes: [
    'id',
    'promoter_member_id',
    'preferred_category_id',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

export default class PromoterPreferredCategoryShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
