<template lang="pug">
b-loading.k-loading(
  :is-full-page="isFullPage"
  :active="active"
  @update:active="updateActiveHandler"
)
</template>

<script>
import { defineComponent, onMounted, ref, computed, reactive } from 'vue'

export default defineComponent({
  props: {
    isFullPage: { type: Boolean, required: false },
    active: { type: Boolean, required: false }
  },

  setup(props, { emit }) {
    const updateActiveHandler = (value) => {
      emit('active', value)
    }

    return {
      updateActiveHandler
    }
  }
})
</script>
