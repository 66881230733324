<template lang="pug">
.vc-common-card-title
  template(v-if="avatarUrl")
    .avatar
      img(:src="avatarUrl")
  template(v-else)
    .placeholder
  .title(:title="title") {{ title }}
  .extra
    slot
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  // components: {},
  // mixins: [],
  props: {
    avatarUrl: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    }
  }
  // setup() {
  //   return {}
  // }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
})
</script>
