<template lang="pug">
b-tag.k-tag.k-button(
  :class="classTag"
  :expanded="expanded"
)
  slot
</template>

<script>
import { defineComponent, computed } from 'vue'

const TYPE_MAP = {
  'is-info': 'is-info',
  'is-primary': 'is-primary',
  'is-warning': 'is-warning',
  'is-success': 'is-success',
  'is-danger': 'is-danger',
  'ci-blue': 'is-ci-blue',
  'ci-pink': 'is-shadow is-outlined is-tickle-me-pink'
}

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      validator(value) {
        return Object.keys(TYPE_MAP).includes(value)
      }
    },
    href: { type: String, required: false },
    expanded: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false },
    loading: { type: Boolean, required: false }
  },

  setup(props, { emit }) {
    const classTag = computed(() => {
      return TYPE_MAP[props.type]
    })

    const clickHandler = (event) => {
      emit('click', event)
    }

    return {
      classTag,
      clickHandler
    }
  }
})
</script>
