import * as types from './mutation-types'
import PromoterMatchView from '../../resource_models/promoter_match_view'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PromoterMatchView.all(options)
      .then((response) => {
        commit(types.FETCH_PROMOTER_MATCH_VIEWS_SUCCESS, response)

        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('promoters/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterPreferredCategoryShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productCategories/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PromoterMatchView.find(id)
      .then((response) => {
        commit(types.GET_PROMOTER_MATCH_VIEW_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTER_MATCH_VIEW_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTER_MATCH_VIEW_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PROMOTER_MATCH_VIEW_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PROMOTER_MATCH_VIEWS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PROMOTER_MATCH_VIEW_SUCCESS, response)

    resolve(response)
  })
}

export const fetchAnswerableProducts = (
  { dispatch, commit },
  { model, options }
) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    model
      .fetchAnswerableProducts(options)
      .then((response) => {
        commit(types.API_REQUEST_START, 'fetchAnswerableProducts')

        dispatch(
          'promoterMatchProducts/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: { model, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const answer = (
  { dispatch, commit },
  { model, matchProductId, answer }
) => {
  commit(types.API_REQUEST_START, 'answer')

  return new Promise((resolve, reject) => {
    model
      .answer({
        matchProductId,
        answer
      })
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MATCH_VIEW_SUCCESS, response)

        dispatch(
          'promoterMatchAnswers/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: answer,
            ref: {
              dispatch,
              commit
            },
            params: { model, matchProductId, answer }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
