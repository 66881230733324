<template lang="pug">
k-button(
  type="shadow-border"
  @click="showDetail"
)
  span 審核未通過
</template>

<script>
import { defineComponent, computed } from 'vue'
import KButton from '@sharedComponents/common/k-button.vue'
import {
  useToast,
  useStore,
  useInstance,
  useModal,
  useI18n
} from 'skid-composables'
import RejectedView from './rejected-view.vue'

export default defineComponent({
  components: {
    KButton
  },

  props: {
    promoterShare: {
      type: Object,
      required: true
    },

    promoterCampaign: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const vueInstance = useInstance()

    const showDetail = () => {
      useModal({
        parent: vueInstance,
        component: RejectedView,
        width: '500px',
        props: {
          promoterShare: props.promoterShare,
          promoterCampaign: props.promoterCampaign
        }
      })
    }

    return {
      showDetail
    }
  }
})
</script>
