<template lang="pug">
k-button(type="is-warning")
  span 報名已額滿
</template>

<script>
import { defineComponent, computed } from 'vue'
import KButton from '@sharedComponents/common/k-button.vue'
import {
  useToast,
  useStore,
  useInstance,
  useModal,
  useI18n
} from 'skid-composables'
import DetailView from './detail-view.vue'

export default defineComponent({
  components: {
    KButton
  },

  props: {
    promoterCampaign: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const vueInstance = useInstance()

    return {}
  }
})
</script>
