import { computed, reactive } from 'vue'
import { useStore, useModal, useInstance } from 'skid-composables'
import axios from 'axios'
import localForage from 'localforage'
import ActionConfirmService from '@services/action_confirm_service.js'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import PromoterMatchView from '@models/promoter_match_view.js'
import Confirmation from '../components/common/confirmation.vue'

const MATCH_VIEW_KEY = 'match-view-config'

export default ({ options = {} }) => {
  const store = useStore()
  const vueInstance = useInstance()
  const data = reactive({
    matchViewConfig: null,
    promoterShareId: null,
    isLoaded: false,
    answeredMatchProductIds: [],
    answersFromLocalForage: [],
    viewRound: null
  })

  const fetchInitData = async ({ promoterShareId }) => {
    data.promoterShareId = promoterShareId

    if (!data.promoterShareId) _switchApiToUserMode()

    await _initMatchViewConfigFromLocalForage()

    await _saveMatchViewToServer()

    await _fetchMatchProducts({
      isInit: true,
      pageSize: 10
    })

    await _fetchMatchProductVideos()

    data.isLoaded = true
  }

  const answer = async ({ matchProductId, answer }) => {
    await store
      .dispatch('promoterMatchViews/answer', {
        model: promoterMatchView.value,
        matchProductId: matchProductId,
        answer: answer
      })
      .catch((response) => {
        if (response.response.data.code === 'promoter_login_required')
          _showLoginMessage()
      })

    data.answeredMatchProductIds.push(matchProductId)

    if (data.answeredMatchProductIds.length % 5 === 0)
      await _fetchMatchProducts({
        isInit: false,
        pageSize: 10
      })
  }

  const tryAgainHandler = async () => {
    data.viewRound += 1
    data.answeredMatchProductIds = []

    await _fetchMatchProducts({
      isInit: false,
      pageSize: 10
    })
  }

  // ====  computed ====

  const promoterMatchView = computed(() => {
    return store.getters['promoterMatchViews/all'].find((matchView) => {
      return matchView.view_id === data.matchViewConfig.viewId
    })
  })

  const promoterMatchProducts = computed(() => {
    return store.getters['promoterMatchProducts/all']
  })

  const promoterShare = computed(() => {
    return store.getters['promoterShares/find'](data.promoterShareId)
  })

  const unanswerMatchProductIds = computed(() => {
    return promoterMatchProducts.value.filter((matchProduct) => {
      return !data.answeredMatchProductIds.includes(matchProduct.id)
    })
  })

  const _initMatchViewConfigFromLocalForage = async () => {
    let matchViewConfig = await localForage.getItem(MATCH_VIEW_KEY)

    if (!matchViewConfig || moment().unix() > matchViewConfig.expiredAt) {
      matchViewConfig = {
        viewId: uuidv4(),
        expiredAt: moment().add(1, 'day').startOf('day').unix()
      }

      await localForage.setItem(MATCH_VIEW_KEY, matchViewConfig)
    }

    data.matchViewConfig = matchViewConfig
  }

  const _refreshMatvhViewConfig = async () => {
    await localForage.removeItem(MATCH_VIEW_KEY)

    await _initMatchViewConfigFromLocalForage()
  }

  const _switchApiToUserMode = () => {
    // 切換 api scope 為 user，避免產生 403
    if (axios.defaults.headers.common['Application-Scope'] === 'admin') return

    axios.defaults.headers.common['Application-Scope'] = 'user'
  }

  const _saveMatchViewToServer = async () => {
    return store
      .dispatch(
        'promoterMatchViews/save',
        new PromoterMatchView({
          view_id: data.matchViewConfig.viewId,
          share_id: data.promoterShareId
        })
      )
      .catch(async (response) => {
        if (response.response.data.code === 'view_id_taken')
          await _resetViewIdAndSaveToServer()
      })
  }

  const _resetViewIdAndSaveToServer = async () => {
    await _refreshMatvhViewConfig()

    await _saveMatchViewToServer()
  }

  const _fetchMatchProducts = async ({ isInit, pageSize }) => {
    const response = await store.dispatch(
      'promoterMatchViews/fetchAnswerableProducts',
      {
        model: promoterMatchView.value,
        options: {
          isInit: isInit,
          viewRound: data.viewRound,
          pageNumber: 1,
          pageSize: pageSize
        }
      }
    )

    if (isInit) data.viewRound = response.data.meta.current_view_round
  }

  const _fetchMatchProductVideos = async () => {
    await store.dispatch('products/fetchVideos', {
      search: {
        product_id_in: promoterMatchProducts.value.map(
          (product) => product.product_id
        )
      }
    })
  }

  const _showLoginMessage = () => {
    useModal({
      parent: vueInstance,
      component: Confirmation,
      props: {
        content: '註冊或登入成為分潤會員',
        confirmText: '登入',
        cancelText: '註冊'
      },
      canCancel: false,
      events: {
        confirm: () => {
          window.location.href = '/promoters/login?back_url=/kolcenter/match'
        },
        cancel: () => {
          window.location.href = '/kol/sign_up?back_url=/kolcenter/match'
        }
      }
    })
  }

  return {
    fetchInitData,

    isLoaded: computed(() => data.isLoaded),

    promoterShare,

    promoterMatchProducts,

    unanswerMatchProductIds,

    tryAgainHandler,

    answer
  }
}
