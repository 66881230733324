export const FETCH_ORDER_SHIPMENT_TRANSITION_STATES_SUCCESS =
  'FETCH_ORDER_SHIPMENT_TRANSITION_STATES_SUCCESS'
export const GET_RELATED_ORDER_SHIPMENT_TRANSITION_STATES_SUCCESS =
  'GET_RELATED_ORDER_SHIPMENT_TRANSITION_STATES_SUCCESS'
export const GET_ORDER_SHIPMENT_TRANSITION_STATE_SUCCESS =
  'GET_ORDER_SHIPMENT_TRANSITION_STATE_SUCCESS'
export const ADD_ORDER_SHIPMENT_TRANSITION_STATE_SUCCESS =
  'ADD_ORDER_SHIPMENT_TRANSITION_STATE_SUCCESS'
export const UPDATE_ORDER_SHIPMENT_TRANSITION_STATE_SUCCESS =
  'UPDATE_ORDER_SHIPMENT_TRANSITION_STATE_SUCCESS'
export const DELETE_ORDER_SHIPMENT_TRANSITION_STATE_SUCCESS =
  'DELETE_ORDER_SHIPMENT_TRANSITION_STATE_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
