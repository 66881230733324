<template lang="pug">
.vc-kolcenter-confirmation
  .content(v-html="content")
  .action
    k-button(
      type="shadow"
      @click="cancel"
    ) {{ cancelText }}
    k-button(
      type="ci-blue"
      @click="confirm"
    ) {{ confirmText }}
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue'
import { useInstance } from 'skid-composables'
import KButton from '@sharedComponents/common/k-button.vue'

const props = defineProps({
  content: { type: String, required: true },
  confirmText: { type: String, required: true },
  cancelText: { type: String, required: true }
})
const emit = defineEmits(['confirm', 'cancel'])

const vueInstance = useInstance()

const confirm = () => {
  emit('confirm')
  vueInstance.$parent.close()
}

const cancel = () => {
  emit('cancel')
  vueInstance.$parent.close()
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'

.vc-kolcenter-confirmation
  +utils.is-flat-card
  +utils.setting-form
  padding: size.$gap

  .content
    padding-bottom: 2rem
  .action
    display: flex
    justify-content: space-between
    margin: 0 size.$gap * -0.5
    .button
      width: 50%
      margin: 0 size.$gap * 0.5
      text-align: center
</style>
