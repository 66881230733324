<template lang="pug">
.vc-kolcenter-match-finished-card
  .card
    .inner
      .title
        | 喔喔 沒有卡片囉!!
      .info-overlay
      .actions
        button.button.is-shadow(@click="clickHomeHandler")
          .icon
            i.fa.fa-home
          span 回首頁
        button.button.is-ci-blue(@click="clickTryAgainHandler")
          .icon
            i.fa.fa-repeat
          span 再選擇一次
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup(props, { emit }) {
    const clickTryAgainHandler = () => {
      emit('click-try-again')
    }

    const clickHomeHandler = () => {
      emit('click-home')
    }

    return { clickTryAgainHandler, clickHomeHandler }
  }
})
</script>

<style lang="sass">
@use '../../../../../application/css/base/variables'
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'

.vc-kolcenter-match-finished-card
  height: 100%
  display: flex
  position: relative

  width: 100%
  max-height: 720px
  height: calc(var(--vh, 1vh) * 100 - variables.$v-navbar-mobile-height)
  +utils.from(size.$ipad)
    height: calc(100vh - variables.$v-navbar-height)

  .card
    padding: size.$gap
    width: 100%
    height: 100%
    max-width: size.$iphone12-pro-max
    position: absolute
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    z-index: 2
    &:not(:first-child)
      z-index: -1
    &:nth-child(2)
      z-index: 1

  .inner
    height: 100%
    border-radius: size.$gap
    overflow: hidden
    box-shadow: 0 0 size.$gap rgba(color.$black, .2)
    background-color: color.$white
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: relative

    &:after
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-image: linear-gradient(180deg, rgba(color.$black, 0) 70%, rgba(color.$black, 0.3) 100%)
      z-index: 1

  .title
    font-size: font.$size-3
    margin-bottom: size.$gap * 3

  .actions
    position: relative
    z-index: 2
    width: 100%
    margin-bottom: size.$gap * 3
    display: flex
    justify-content: space-around
    padding: 0 size.$gap
</style>
