<template lang="pug">
.vc-kolcenter-product-show-container
  .od-container.-has-padding
    b-loading(
      :active="!data.isLoaded"
      :is-full-page="false"
    )
    .header-wrapper
      header-view(
        :last-page-title="lastPageTitle"
        @click-last-page="clickLastPageHandler"
      )

    .basic-info-wrapper
      basic-info-view(
        :product="product"
        :promoter-event-product-ship="promoterEventProductShip"
        :promoter-share="promoterShare"
      )

    .detail-info-wrapper(ref="productDetail")
      detail-info-view(
        v-if="data.isLoaded"
        :product="product"
      )
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore, useInstance } from 'skid-composables'
import headerView from './show_container/header-view.vue'
import basicInfoView from './show_container/basic-info-view.vue'
import detailInfoView from './show_container/detail-info-view.vue'

const props = defineProps({
  product: { type: Object, required: true },
  promoterEventProductShip: { type: Object }, // 選品配對不會有檔期資訊
  promoterShare: { type: Object }, // 如果是訪客，則不會有 promoterShare
  lastPageTitle: {
    type: String,
    default() {
      return '上一頁'
    }
  },
  source: {
    type: String,
    required: false,
    validator(value) {
      return ['kolcenter', 'match'].includes(value)
    },
    default() {
      return 'kolcenter'
    }
  },

  isScrollToDetail: {
    type: Boolean,
    required: false
  }
})

const productDetail = ref(null)

const store = useStore()

const data = reactive({
  isLoaded: false
})

const vueInstance = useInstance()

const clickLastPageHandler = () => {
  vueInstance.$parent.close()
}

const fetchProduct = async () => {
  switch (props.source) {
    case 'kolcenter':
      await store.dispatch('products/find', props.product.id) // to fetch product images

      await store.dispatch('products/fetchSupportDocuments', props.product)

      return
    case 'match':
      // 當使用者為 guest 且商品未在前台上架，則無法用原來的 product api 取得資料
      await store.dispatch('promoterMatchProducts/find', props.product.id)

      return
  }
}

onMounted(async () => {
  try {
    await fetchProduct()

    data.isLoaded = true
  } catch (e) {
    data.isLoaded = true
  }

  if (props.isScrollToDetail) {
    setTimeout(() => {
      productDetail.value.scrollIntoView({ behavior: 'smooth' })
    }, 300)
  }
})
</script>

<style lang="sass">
@use 'sass:math'
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/color'

.vc-kolcenter-product-show-container
  background: color.$white
  +utils.from(size.$ipad)
    padding: size.$gap 0
    padding-top: size.$gap

  > .od-container
    display: flex
    flex-direction: column

  .header-wrapper
    position: relative
    margin-bottom: size.$gap
    +utils.until(size.$ipad)
      top: size.$gap * 0.5
      position: absolute
      margin-bottom: 0
      z-index: 2
      a
        color: color.$white
        text-shadow: 0 0 8px rgba(color.$black, .5)
</style>
