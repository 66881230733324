<template lang="pug">
.button.join-button.is-outlined.is-shadow.is-ci-blue(
  :class="{ 'is-loading': isLoading }"
  @click="joinHandler"
)
  .icon
    i.mdi.mdi-plus
  span 加入
</template>

<script>
import { defineComponent, computed } from 'vue'
import {
  useToast,
  useStore,
  useInstance,
  useModal,
  useI18n
} from 'skid-composables'
import Confirmation from '../../common/confirmation.vue'

export default defineComponent({
  props: {
    promoterShare: {
      type: Object,
      required: true
    },

    promoterCampaign: {
      type: Object,
      required: true
    },

    joinedHandler: {
      type: Function,
      required: false
    }
  },

  setup(props, { emit }) {
    const store = useStore()
    const vueInstance = useInstance()
    const i18n = useI18n()

    const isLoading = computed(() => {
      return store.getters['promoterCampaignShareShips/isLoading']
    })

    const confirmation = ({ title, confirmButtonText }) => {
      return new Promise((resolve, reject) => {
        useModal({
          parent: vueInstance,
          component: Confirmation,
          width: '500px',
          props: {
            content: title,
            confirmText: confirmButtonText,
            cancelText: i18n.actionLocaleText('cancel')
          },
          events: {
            confirm: () => {
              resolve()
            },
            cancel: () => {}
          }
        })
      })
    }

    const joinHandler = async () => {
      await confirmation({
        title: `確定要加入 ${props.promoterCampaign.name}`,
        // text: '這個動作將無法復原',
        confirmButtonText: '確認加入'
      })

      await store.dispatch('promoterCampaignShareShips/acceptInvitation', {
        share_id: props.promoterShare.id,
        campaign_id: props.promoterCampaign.id
      })

      useToast({
        message: `您已成功加入${props.promoterCampaign.name}，檔期商品已加入到您的賣場`,
        type: 'is-success'
      })

      props.joinedHandler && props.joinedHandler()
    }

    return {
      isLoading,
      joinHandler
    }
  }
})
</script>
