import OrderShipmentTransitionState from '../../resource_models/order_shipment_transition_state'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map(
    (id) => new OrderShipmentTransitionState(state.entities[id])
  )
}

export const find = (state) => (id) => {
  return new OrderShipmentTransitionState(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
