<template lang="pug">
.vc-kolcenter-common-swipe-cards(ref="container")
  .cards
    .card(
      v-for="row in data"
      :key="row.id"
      :data-id="row.id"
      ref="cards"
    )
      slot(:row="row")

  .match-status.like
    .status-icon
      img.logo(:src="KIconUrl")
    .status-title
      span My Select
    .status-tags
      span.tag
        | 有興趣
      span.tag
        | 想賣
      span.tag
        | 我的選品

  .match-status.super-like
    .status-icon
      img.logo(:src="KIconUrl")
    .status-title
      span My Favorite
    .status-tags
      span.tag
        | 超喜歡
      span.tag
        | 超推薦
      span.tag
        | 賣爆

  .match-status.dislike
    .status-icon
      img.logo(:src="KIconUrl")
    .status-title
      span No Interesting
    .status-tags
      span.tag
        | 沒興趣
      span.tag
        | 待加強

  .options
    .option
      button.button.dislike(@click.prevent="swipeDislike")
        span.icon
          i.mdi.mdi-close
    .option
      button.button.super-like(@click.prevent="swipeSuperLike")
        span.icon
          i.mdi.mdi-star-circle
    .option
      button.button.like(@click.prevent="swipeLike")
        span.icon
          i.mdi.mdi-heart-outline
</template>

<script>
import { defineComponent, onMounted, ref, computed, watch } from 'vue'
import { gsap, Draggable, Power1 } from 'gsap/all'
import KIcon from '../../../images/K.svg'

export default defineComponent({
  props: {
    data: {
      type: Array,
      required: true
    }
  },

  setup(props, { emit }) {
    // GASP
    gsap.registerPlugin(Draggable, Power1)

    // data
    const container = ref(null)

    // mounted
    onMounted(async () => {
      await _loadSwiper()
      _playVideo(0)
    })

    const cardData = computed(() => props.data)

    /**
     * @returns {computedRef<String>}
     */
    const KIconUrl =
      computed <
      String >
      (() => {
        console.log(KIcon)
        return KIcon
      })

    watch(cardData, () => {
      setTimeout(() => _loadSwiper(), 1000) // add timeout to ensure card doms are rendered
    })

    // mtehods
    /**
     * @returns {void}
     */
    const _loadSwiper = () => {
      return new Promise((resolve, reject) => {
        document.querySelectorAll('.card:not(.loaded)').forEach((el) => {
          Draggable.create(el, {
            type: 'x, y',
            inertia: true,
            onClick: function () {
              let endX = Math.round(this.endX)

              if (Math.abs(endX) <= 60) _initCardPosition(this.target)
            },
            onDrag: function () {
              let endX = Math.round(this.endX)

              if (endX > 60) container.value.classList.add('interested')
              else if (endX < -60) container.value.classList.add('uninterested')
              else
                container.value.classList.remove(
                  ...['interested', 'uninterested']
                )
            },
            onDragEnd: function () {
              let endX = Math.round(this.endX)

              if (endX > 60) swipeLike()
              else if (endX < -60) swipeDislike()
              else _initCardPosition(this.target)
            },
            dragClickables: false
          })

          el.classList.add('loaded')
        })
        resolve()
      })
    }

    /**
     * @return {void}
     */
    const _initCardPosition = (target) => {
      gsap
        .timeline({
          repeat: 0,
          yoyo: false,
          repeatDelay: 0
        })
        .to(target, {
          duration: 0.3,
          x: 0,
          y: 0,
          rotation: 0,
          ease: Power1.easeOut
        })
    }

    /**
     * @returns {object}
     */
    const currentSwipe = (card, answer) => {
      return gsap.timeline({
        repeat: 0,
        yoyo: false,
        repeatDelay: 0,
        onStart: showMatchStatus,
        onStartParams: [answer],
        onComplete: remove,
        onCompleteParams: [card, answer]
      })
    }

    /**
     * @returns {void}
     */
    const swipeLike = () => {
      let card = container.value.querySelector('.card')

      if (!card) return

      currentSwipe(card, 'interested').to(card, {
        duration: 0.3,
        x: '+=800',
        y: '+=300',
        rotation: '+=30',
        ease: Power1.easeOut
      })
    }

    /**
     * @returns {void}
     */
    const swipeDislike = () => {
      let card = container.value.querySelector('.card')

      if (!card) return

      currentSwipe(card, 'uninterested').to(card, {
        duration: 0.3,
        x: '-=800',
        y: '+=300',
        rotation: '-=30',
        ease: Power1.easeOut
      })
    }

    /**
     * @returns {void}
     */
    const swipeSuperLike = () => {
      let card = container.value.querySelector('.card')

      if (!card) return

      currentSwipe(card, 'super_like').to(card, {
        duration: 0.3,
        x: '+=800',
        y: '+=300',
        rotation: '+=30',
        ease: Power1.easeOut
      })
    }

    /**
     * @returns {void}
     */
    const showMatchStatus = (answer) => {
      container.value.classList.add(answer)

      setTimeout(() => {
        container.value.classList.remove(answer)
        _playVideo(0)
      }, 2000)
    }

    /**
     * @returns {void}
     */
    const remove = (card, answer) => {
      container.value.classList.remove(answer)
      card.remove()
      _answer(card.dataset.id, answer)
    }

    /**
     * @returns {void}
     */
    const _answer = (id, answer) => {
      const row = props.data.find((row) => row.id == id)
      emit('answer', { row: row, answer })
    }

    /**
     * @returns {void}
     */
    const _playVideo = (index) => {
      const video = container.value
        .querySelectorAll('.card')
        [index].querySelector('video')
      const volumnButton = container.value
        .querySelectorAll('.card')
        [index].querySelector('.volumn-button')

      if (!video) return

      const promise = video.play()

      if (promise !== undefined) {
        volumnButton.classList.add('muted')
        video.muted = true
        video.play()
      }
    }

    return {
      container,
      KIconUrl,
      swipeLike,
      swipeSuperLike,
      swipeDislike
    }
  }
})
</script>

<style lang="sass">
@use 'sass:math'
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'

.vc-kolcenter-common-swipe-cards
  width: 100%
  max-height: size.$iphone12-pro-max-height
  height: 100%
  position: relative
  +utils.z-index(match-page)

  &.interested .match-status.like
    opacity: 0.8
    transform: scale(1)

  &.super_like .match-status.super-like
    opacity: 0.8
    transform: scale(1)

  &.uninterested .match-status.dislike
    opacity: 0.8
    transform: scale(1)

  .match-status
    display: grid
    grid-template-columns: 1fr 3fr
    position: absolute
    z-index: 9999
    top: 5%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    padding: size.$gap * 1.5
    width: 100%
    max-width: calc(size.$iphone12-pro-max - size.$gap * 6)
    pointer-events: none
    border-radius: 30px
    border: 1px solid color.$ford-gray
    color: color.$white
    opacity: 0
    transition: .3s opacity
    &.like
      border-color: color.$malibu
      background-image: radial-gradient(color.$sky-blue 0%, color.$deep-sky-blue 100%)
    &.super-like
      border-color: color.$muted-yellow
      background-image: linear-gradient(color.$dunes-beige 0%, color.$gold 100%)
    &.dislike
      border-color: color.$deep-pink
      background-image: linear-gradient(color.$begonia 0%, color.$napthol-crimson 100%)
    > .status-icon
      display: grid
      grid-row: 1 / 3
      justify-content: center
      align-items: center
    > .status-title
      font-size: font.$size-4
      font-weight: font.$bold
      letter-spacing: 0px
    > .status-tags
      font-size: font.$size-6
      font-weight: font.$semibold
      letter-spacing: 0px
    .logo
      $logo-width: 48px

      display: block
      width: $logo-width
      height: $logo-width
      margin-left: auto
      margin-right: auto
    .tag
      &:not(:last-child)
        margin-right: math.div(size.$gap, 2)
      &:before
        content: '#'
        margin-right: math.div(size.$gap, 3)

  .cards
    height: 100%
    display: flex
    position: relative

    .card
      padding: size.$gap
      width: 100%
      height: 100%
      max-width: size.$iphone12-pro-max
      position: absolute
      left: 0
      right: 0
      margin-left: auto
      margin-right: auto
      z-index: 2
      &:not(:first-child)
        pointer-events: none
        z-index: -1
        > .vc-common-swipe-card-card
          box-shadow: none
      &:nth-child(2)
        z-index: 1

  .options
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    position: absolute
    bottom: 2%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    padding: size.$gap*2 0
    z-index: 9999
    width: 100%
    max-width: calc(size.$iphone12-pro-max - size.$gap * 10)
    .option
      display: grid
      justify-content: center
    .button
      $button-width: 60px

      display: flex
      align-items: center
      justify-content: center
      border-radius: 20px
      height: $button-width
      width: $button-width
      background-color: rgba(color.$black, .5)
      box-shadow: 0 0 size.$gap rgba(color.$black, .1)
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, color.$black 100%)
      &:focus
        outline: 0
      &.dislike
        border: 1px solid color.$deep-pink
      &.super-like
        border: 1px solid color.$gold
      &.like
        border: 1px solid color.$deep-sky-blue

    i
      font-size: 32px
      vertical-align: middle
  .mdi-close
    color: color.$deep-pink

  .mdi-star-circle
    color: color.$gold

  .mdi-heart-outline
    color: color.$deep-sky-blue
</style>
