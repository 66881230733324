<template lang="pug">
k-button(
  type="ci-blue"
  @click="showRegistration"
)
  k-icon(icon="mdi-plus")
  span {{ title }}
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore, useInstance, useModal, useI18n } from 'skid-composables'
import moment from 'moment'
import RegisterForm from './register-form.vue'
import KIcon from '@sharedComponents/common/k-icon.vue'
import KButton from '@sharedComponents/common/k-button.vue'

const props = defineProps({
  promoterShare: { type: Object, required: true },
  promoterCampaign: { type: Object, required: true },
  isShowRegister: { type: Boolean, required: false }
})

const store = useStore()
const vueInstance = useInstance()
const i18n = useI18n()

const isLoading = computed(() => {
  return store.getters['promoterCampaignShareShips/isLoading']
})

const showRegistration = async () => {
  useModal({
    parent: vueInstance,
    component: RegisterForm,
    width: '500px',
    scroll: 'keep',
    canCancel: ['x'],
    props: {
      promoterShare: props.promoterShare,
      promoterCampaign: props.promoterCampaign
    }
  })
}

const title = computed(() => {
  return props.promoterCampaign.registerText()
})

onMounted(() => {
  if (props.isShowRegister) showRegistration()
})
</script>
