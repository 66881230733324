<template lang="pug">
k-tag(:type="stateType")
  | {{ aasmStateLocaleText('promoter/campaign_sample_shipment', 'state', promoterCampaignSampleShipment.state) }}
</template>

<script>
import { defineComponent, computed } from 'vue'
import KTag from '../common/k-tag.vue'

export default defineComponent({
  components: {
    KTag
  },

  props: {
    promoterCampaignSampleShipment: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const stateType = computed(() => {
      const state = props.promoterCampaignSampleShipment.state

      switch (state) {
        case 'pending':
          return 'is-warning'
        case 'ready':
          return 'is-warning'
        case 'tallying':
          return 'is-warning'
        case 'shipped':
          return 'ci-blue'
        case 'canceled':
          return 'is-danger'
      }
    })

    return { stateType }
  }
})
</script>
