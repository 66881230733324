import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_match_views',
  attributes: [
    'id',
    'share_id',
    'view_id',
    'interesteds_count',
    'uninteresteds_count',
    'super_likes_count',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['view_id', 'share_id']
}

export default class PromoterMatchView extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  fetchAnswerableProducts(options) {
    let query = FetchingDataOptionsService.call(options)
    if (options.viewRound != null) query += `&view_round=${options.viewRound}`
    if (options.isInit) query += `&is_init=${options.isInit}`

    return axios.get(
      `${this.apiBasePath()}/${this.id}/answerable_products?${query}`
    )
  }

  answer({ matchProductId, answer }) {
    return axios.put(
      `${this.apiBasePath()}/${
        this.id
      }/answer?match_product_id=${matchProductId}&answer=${answer}`
    )
  }
}
