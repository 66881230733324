<template lang="pug">
.vc-kolcenter-match-cards
  swipe-cards(
    :data="promoterMatchProducts"
    @answer="answer"
  )
    template(v-slot="props")
      swipe-card(
        :data="cardFor(props.row)"
        @click-overlay="clickHandler"
      )

  b-modal(
    :active.sync="isDetailShow"
    :can-cancel="['x', 'outside']"
  )
    product-detail(
      :product="config.product"
      :last-page-title="'選品配對'"
      source="match"
      is-scroll-to-detail
    )
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { useStore } from 'skid-composables'
import SwipeCards from '@kolcenterComponents/common/swipe-cards.vue'
import SwipeCard from '@kolcenterComponents/common/swipe_card/card.vue'
import ProductDetail from '@kolcenterComponents/products/show-container.vue'

export default defineComponent({
  components: {
    SwipeCards,
    SwipeCard,
    ProductDetail
  },

  props: {
    promoterMatchProducts: {
      type: Array,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore()

    onMounted(() => {})

    const answer = ({ row, answer }) => {
      emit('answer', { matchProductId: row.id, answer })

      isDetailShow.value = false
    }

    const productFor = (promoterMatchProduct) => {
      const product = store.getters['products/find'](
        promoterMatchProduct.product_id
      )

      return product
    }

    const videoFor = (product) => {
      return store.getters['products/findVideoFor'](product.id)
    }

    const cardFor = (promoterMatchProduct) => {
      const product = productFor(promoterMatchProduct)
      const video = videoFor(product)

      return {
        id: product.id,
        cover: {
          url: product.cover.url
        },
        video: {
          url: video.video?.url
        },
        name: product.name
      }
    }

    // for product detail
    const isDetailShow = ref(false)
    const config = reactive({
      product: null
    })

    const clickHandler = async ({ data }) => {
      config.product = store.getters['products/find'](data.id)

      isDetailShow.value = true
    }

    return {
      answer,

      cardFor,

      clickHandler,

      isDetailShow,

      config
    }
  }
})
</script>

<style lang="sass">
@use '../../../../../application/css/base/variables'
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'

.vc-kolcenter-match-cards
  .modal
    z-index: 99999
  height: calc(100vh - variables.$v-navbar-mobile-height)
  height: calc(var(--vh, 1dvh) * 100 - variables.$v-navbar-mobile-height)
  +utils.from(size.$ipad)
    height: calc(100vh - variables.$v-navbar-height)
    height: calc(var(--vh, 1dvh) * 100 - variables.$v-navbar-height)
</style>
